// src/components/tasks/TaskForm.js
import React, { useState } from 'react';
import { Button, TextField, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'; // Import the DatePicker component
import { AddButton, FormContainer } from '../../styles/StyledComponents';

export default function TaskForm({ onAddTask, loading }) {
    const [newTask, setNewTask] = useState({
        name: '',
        description: '',
        dueDate: null, // Initialize dueDate as null
    });

    // Function to handle adding a task
    const handleAddTask = () => {
        if (newTask.name.trim()) {
            onAddTask(newTask);
            setNewTask({ name: '', description: '', dueDate: null }); // Clear the form after adding
        }
    };

    return (
        <FormContainer>
            <TextField
                label="Task Name"
                variant="outlined"
                value={newTask.name}
                onChange={(e) => setNewTask({ ...newTask, name: e.target.value })}
                fullWidth
            />
            <TextField
                label="Description"
                variant="outlined"
                value={newTask.description}
                onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
                fullWidth
                multiline
                rows={2}
            />
            <DatePicker
                label="Due Date"
                value={newTask.dueDate}
                onChange={(date) => setNewTask({ ...newTask, dueDate: date })}
                renderInput={(params) => <TextField {...params} fullWidth />}
            />
            <AddButton
                variant="contained"
                onClick={handleAddTask}
                disabled={loading}
            >
                Add Task
            </AddButton>
        </FormContainer>
    );
}

// src/components/tasks/TaskList.js
import React, { useContext, useState } from 'react';
import { Card, CardContent, CardActions, Button, Typography, TextField } from '@mui/material';
import { GlobalContext } from '../../context/GlobalContext';
import { Box } from '@mui/material';

function TaskList({ tasks }) {
    const { deleteTask, doneTask, updateTask } = useContext(GlobalContext);

    // State to track which task is being edited and the edited values
    const [editingTaskId, setEditingTaskId] = useState(null);
    const [editedTask, setEditedTask] = useState({ name: '', description: '' });

    if (!tasks || tasks.length === 0) {
        return <Typography variant="body1" color="textSecondary" align="center">No tasks available</Typography>;
    }

    const handleDelete = (taskId) => {
        deleteTask(taskId);
    };

    const handleToggleDone = (taskId, currentStatus) => {
        doneTask(taskId, !currentStatus);
    };

    const handleEditClick = (task) => {
        setEditingTaskId(task.id);
        setEditedTask({ name: task.name, description: task.description });
    };

    const handleSaveClick = (taskId) => {
        updateTask(taskId, editedTask);
        setEditingTaskId(null);
        setEditedTask({ name: '', description: '' });
    };

    const handleCancelClick = () => {
        setEditingTaskId(null);
        setEditedTask({ name: '', description: '' });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedTask((prev) => ({ ...prev, [name]: value }));
    };

    const handleKeyPress = (e, taskId) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSaveClick(taskId);
        }
    };

    return (
        <div>
            {tasks.map((task) => (
                <Card
                    key={task.id}
                    sx={{
                        marginBottom: 2,
                        borderRadius: '10px',
                        backgroundColor: 'var(--surface)',
                        boxShadow: '0 2px 8px var(--shadow-light)',
                        color: 'var(--text-primary)',
                    }}
                >
                    <CardContent>
                                            {editingTaskId === task.id ? (
                                                // Show input fields for editing when in edit mode
                                                <>
                                                    <TextField
                                                        label="Task Name"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={editedTask.name}
                                                        name="name"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, task.id)}
                                                        sx={{ marginBottom: 1 }}
                                                    />
                                                    <TextField
                                                        label="Description"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={editedTask.description}
                                                        name="description"
                                                        onChange={handleInputChange}
                                                        onKeyPress={(e) => handleKeyPress(e, task.id)}
                                                        multiline
                                                        rows={2}
                                                    />
                                                </>
                                            ) : (
                                                // Display task details when not in edit mode
                                                <>
                                                    <Typography variant="h6" gutterBottom sx={{ color: 'var(--text-primary)' }}>
                                                        {task.name}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color: 'var(--text-secondary)' }}>
                                                        {task.description}
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                                                        <Typography variant="caption" sx={{ color: 'var(--text-tertiary)' }}>
                                                            Created: {task.dateCreated ? new Date(task.dateCreated).toLocaleString() : 'No date available'}
                                                        </Typography>
                                                        <Typography variant="caption" sx={{ color: 'var(--text-tertiary)' }}>
                                                            Due: {task.dueDate ? new Date(task.dueDate).toLocaleDateString() : 'No due date'}
                                                        </Typography>
                                                    </Box>
                                                </>
                                            )}
                    </CardContent>
                    <CardActions>
                        {editingTaskId === task.id ? (
                            // Show Save and Cancel buttons when editing
                            <>
                                <Button size="small" color="primary" onClick={() => handleSaveClick(task.id)}>
                                    Save
                                </Button>
                                <Button size="small" color="secondary" onClick={handleCancelClick}>
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            // Show Edit, Done, and Delete buttons when not in edit mode
                            <>
                                <Button
                                    size="small"
                                    color={task.done ? 'success' : 'primary'}
                                    onClick={() => handleToggleDone(task.id, task.done)}
                                >
                                    {task.done ? 'Pending' : 'Done'}
                                </Button>
                                <Button size="small" color="default" onClick={() => handleEditClick(task)}>
                                    Edit
                                </Button>
                                <Button size="small" color="secondary" onClick={() => handleDelete(task.id)}>
                                    Delete
                                </Button>
                            </>
                        )}
                    </CardActions>
                </Card>
            ))}
        </div>
    );
}

export default TaskList;

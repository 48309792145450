// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDDBFpyWT1w350WB9g3zCqeFpV7cRKnQf0",
  authDomain: "squirrel-tamer.firebaseapp.com",
  projectId: "squirrel-tamer",
  storageBucket: "squirrel-tamer.appspot.com",
  messagingSenderId: "311135200337",
  appId: "1:311135200337:web:10bb3a3f3b7ff556f8c470"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
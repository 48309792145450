// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#4A90E2', // Same as --primary-main for consistency
            light: '#B3D4FC',
            dark: '#1C3A72',
            contrastText: '#FFFFFF', // White text on primary background
        },
        secondary: {
            main: '#34A853', // Green for secondary actions
            light: '#A8D5A2',
            dark: '#1B5E20',
            contrastText: '#FFFFFF',
        },
        background: {
            default: '#FFFFFF', // Matches --background-light
            paper: '#F5F5F5', // Matches --background-medium for surfaces
        },
        text: {
            primary: '#212121', // Matches --text-primary
            secondary: '#757575', // Matches --text-secondary
        },
    },
    shape: {
        borderRadius: 8, // Consistent border radius for buttons, cards, etc.
    },
    typography: {
        button: {
            textTransform: 'none', // Prevents uppercase transformation of button text
        },
    },
});

export default theme;

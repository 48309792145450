// src/context/GlobalContext.js
import React, { createContext, useState, useEffect } from 'react';
import { db, auth } from '../firebase'; // Adjust the path as needed
import { getDocs, collection, addDoc, deleteDoc, doc, query, where, updateDoc, Timestamp } from 'firebase/firestore'; // Added Timestamp import
import { signOut, onAuthStateChanged } from 'firebase/auth';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchTasks = async () => {
            if (user) {
                setLoading(true);
                try {
                    const q = query(collection(db, 'tasks'), where('userId', '==', user.uid));
                    const querySnapshot = await getDocs(q);
                    const taskList = querySnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data()
                    }));
                    setTasks(taskList);
                } catch (error) {
                    console.error('Error fetching tasks:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchTasks();
    }, [user]);
// In GlobalContext.js, inside the addTask function:
const addTask = async (task) => {
    if (!user) return;
    try {
        const taskWithUserId = {
            ...task,
            userId: user.uid,
            done: false,
            dateCreated: new Date(),
            dueDate: task.dueDate ? task.dueDate.toISOString() : null, // Convert date to string for Firestore storage
        };
        const docRef = await addDoc(collection(db, 'tasks'), taskWithUserId);
        setTasks((prevTasks) => [
            ...prevTasks,
            { id: docRef.id, ...taskWithUserId }
        ]);
    } catch (error) {
        console.error('Error adding task:', error);
    }
};
// Function to update a task's details in Firestore
const updateTask = async (taskId, updatedTask) => {
    if (!user) return;
    try {
        const taskDocRef = doc(db, 'tasks', taskId);

        // Update the task document with the new name and description
        await updateDoc(taskDocRef, {
            name: updatedTask.name,
            description: updatedTask.description,
        });

        // Update the local state to reflect the changes immediately
        setTasks((prevTasks) =>
            prevTasks.map((task) =>
                task.id === taskId ? { ...task, ...updatedTask } : task
            )
        );
    } catch (error) {
        console.error('Error updating task:', error);
    }
};

    const deleteTask = async (taskId) => {
        if (!user) return;
        try {
            await deleteDoc(doc(db, 'tasks', taskId));
            setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
        } catch (error) {
            console.error('Error deleting task:', error);
        }
    };

    const doneTask = async (taskId, newStatus) => {
        if (!user) return;
        try {
            const taskDocRef = doc(db, 'tasks', taskId);
            await updateDoc(taskDocRef, { done: newStatus });
            setTasks((prevTasks) =>
                prevTasks.map((task) =>
                    task.id === taskId ? { ...task, done: newStatus } : task
                )
            );
        } catch (error) {
            console.error('Error updating task status:', error);
        }
    };


    const logout = async () => {
        try {
            await signOut(auth);
            setUser(null);
            setTasks([]);
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <GlobalContext.Provider value={{ tasks, loading, addTask, deleteTask, doneTask, updateTask, user, setUser, logout }}>
            {children}
        </GlobalContext.Provider>
    );
};

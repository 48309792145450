// src/App.js
import React, { useContext } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/theme'; // Adjust path if needed
import TaskManager from './components/tasks/TaskManager';
import Login from './components/login/Login'; // Adjust path if needed
import { GlobalProvider, GlobalContext } from './context/GlobalContext'; // Adjust path if needed
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; // Keep this import

function App() {
    // Use the GlobalContext to access user, loading, handleLogin, and logout functions
    const { user, loading, handleLogin, logout } = useContext(GlobalContext);

    // Debug logs to understand state transitions
    console.log('User:', user);
    console.log('Loading:', loading);

    // If loading is true, show a loading indicator or a simple message
    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <p>Loading...</p>
            </div>
        );
    }

    // Show the Login component if no user is authenticated
    if (!user) {
        return <Login onLogin={handleLogin} />;
    }

    // Show the TaskManager and a logout button if the user is logged in
    return (
        <ThemeProvider theme={theme}>
            <div>
                <TaskManager />
            </div>
        </ThemeProvider>
    );
}

export default function AppWrapper() {
    // Wrap the entire app in the GlobalProvider and LocalizationProvider
    return (
        <GlobalProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <App />
            </LocalizationProvider>
        </GlobalProvider>
    );
}

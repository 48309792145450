// src/styles/StyledComponents.js
import styled from 'styled-components';
import { TextField, Button } from '@mui/material';

// Styled input field using Material-UI's TextField with custom styles
export const InputField = styled(TextField)`
  .MuiInputBase-root {
    background-color: var(--background-card);
    color: var(--text-primary-dark);
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: var(--highlight-purple);
    }

    &:hover fieldset {
      border-color: var(--primary-main);
    }

    &.Mui-focused fieldset {
      border-color: var(--primary-light);
    }
  }

  .MuiInputLabel-root {
    color: var(--text-secondary-dark);
  }
`;

// Styled button using Material-UI's Button with custom styles
export const AddButton = styled(Button)`
  background-color: var(--primary-main);
  color: var(--text-primary-dark);
  border-radius: 5px;
  padding: 10px 20px;
  transition: background 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px var(--shadow-dark);

  &:hover {
    background-color: var(--primary-light);
    transform: translateY(-2px);
  }

  &:disabled {
    background-color: var(--background-card);
    color: var(--text-secondary-dark);
  }
`;

// Styled container for the form
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--background-card);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--shadow-dark);
`;

// Additional components used in TaskList and TaskManager
export const TaskCard = styled.div`
  background: var(--background-card);
  color: var(--text-primary-dark);
  border: 1px solid var(--secondary-main);
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 4px 8px var(--shadow-dark);
  transition: background 0.3s ease;

  &:hover {
    background: var(--background-dark);
  }
`;

export const TaskContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const TaskName = styled.h3`
  color: var(--primary-main);
  margin: 0;
`;

export const DeleteButton = styled.button`
  background: transparent;
  color: var(--secondary-main);
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: var(--accent-warm);
  }
`;

export const Root = styled.div`
  background: var(--background-dark);
  min-height: 100vh;
  padding: 20px;
  color: var(--text-primary-dark);
`;

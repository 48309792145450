// src/components/tasks/TaskManager.js
import React, { useContext, useState } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import { Box, Typography, Tabs, Tab, Button, CircularProgress, Paper } from '@mui/material';
import TaskForm from './TaskForm';
import TaskList from './TaskList';
import { styled } from '@mui/material/styles';

const Container = styled(Paper)({
    padding: '20px',
    maxWidth: '700px',
    margin: '20px auto',
    background: 'var(--background-light)',
    borderRadius: '10px',
    boxShadow: '0px 4px 12px var(--shadow-medium)',
});

export default function TaskManager() {
    const { tasks, loading, addTask, logout } = useContext(GlobalContext);
    const [selectedTab, setSelectedTab] = useState('pending');

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const filteredTasks = tasks.filter((task) => {
        if (selectedTab === 'completed') return task.done;
        if (selectedTab === 'pending') return !task.done;
        return true;
    });

    return (
        <Container>
            <Typography variant="h4" align="center" gutterBottom>
                Task Manager
            </Typography>
            <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                centered
                sx={{ marginBottom: 3 }}
            >
                <Tab label="Pending" value="pending" />
                <Tab label="All" value="all" />
                <Tab label="Completed" value="completed" />

            </Tabs>
            <TaskForm onAddTask={addTask} loading={loading} />
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <TaskList tasks={filteredTasks} />
            )}
<Button onClick={logout} variant="contained" color="secondary" sx={{ marginTop: 2 }}>
                Logout
            </Button>
        </Container>
    );
}

// src/components/login/Login.js

// Import React and necessary hooks for state management
import React, { useState } from 'react';
// Import UI components from Material-UI for building the login UI
import { Button, Card, CircularProgress, Typography, Snackbar } from '@mui/material';
// Import Firebase methods for authentication
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
// Import the auth object from your Firebase configuration
import { auth } from '../../firebase'; // Make sure this points to your Firebase configuration
// Import styled from Material-UI for custom styling
import { styled } from '@mui/material/styles';

// Define the root styling for centering content vertically and horizontally
const Root = styled('div')({
    backgroundColor: '#121212', // Dark background color for the login screen
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Full height to fill the viewport
});

// Define the card styling for the login box
const StyledCard = styled(Card)({
    padding: '20px', // Padding inside the card
    maxWidth: '400px', // Maximum width of the card
    textAlign: 'center', // Center align text
    background: 'linear-gradient(145deg, #1F1F1F, #2A2A2A)', // Gradient background for visual effect
    borderRadius: '15px', // Rounded corners for the card
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
});

// Define the styling for the login button
const LoginButton = styled(Button)({
    background: 'linear-gradient(45deg, #42A5F5, #1976D2)', // Gradient background for the button
    color: '#FFF', // White text color
    marginTop: '20px', // Space above the button
    borderRadius: '25px', // Rounded button
    padding: '10px 20px', // Padding for a larger clickable area
    '&:hover': {
        background: 'linear-gradient(45deg, #1E88E5, #1565C0)', // Darker gradient on hover
    },
});

// Define the Login component, which takes `onLogin` as a prop to update the user in the global state
export default function Login({ onLogin }) {
    // State to manage loading indicator while authentication is in progress
    const [loading, setLoading] = useState(false);
    // State to store any error messages during sign-in attempts
    const [error, setError] = useState(null);
    // State to control visibility of the Snackbar for displaying errors
    const [openSnackbar, setOpenSnackbar] = useState(false);

    // Function to handle Google sign-in
    const handleGoogleSignIn = async () => {
        setLoading(true); // Set loading state to true to show the loader
        const provider = new GoogleAuthProvider(); // Create a new GoogleAuthProvider instance
        try {
            // Use Firebase's signInWithPopup to open the Google sign-in popup
            const result = await signInWithPopup(auth, provider);
            // Call the `onLogin` function with the authenticated user to update the global state
            onLogin(result.user);
        } catch (error) {
            // Log the error to the console for debugging
            console.error('Sign-in error:', error);
            // Set the error message to be displayed in the Snackbar
            setError(error.message);
            // Open the Snackbar to notify the user of the error
            setOpenSnackbar(true);
        } finally {
            // Set loading to false to hide the loader regardless of success or failure
            setLoading(false);
        }
    };

    // Function to handle closing the Snackbar
    const handleCloseSnackbar = () => {
        setOpenSnackbar(false); // Close the Snackbar
    };

    // Return the JSX for the login screen
    return (
        <Root>
            <StyledCard>
                <Typography variant="h4" gutterBottom style={{ color: '#FFF' }}>
                    Welcome to Task Manager
                </Typography>
                {/* Show CircularProgress if loading, otherwise show the login button */}
                {loading ? (
                    <CircularProgress color="secondary" />
                ) : (
                    <LoginButton onClick={handleGoogleSignIn}>
                        Sign in with Google
                    </LoginButton>
                )}
                {/* Snackbar to display error messages */}
                <Snackbar
                    open={openSnackbar} // Control visibility based on `openSnackbar` state
                    autoHideDuration={6000} // Automatically close after 6 seconds
                    onClose={handleCloseSnackbar} // Close Snackbar when user clicks close or after duration
                    message={error} // Display the error message
                />
            </StyledCard>
        </Root>
    );
}
